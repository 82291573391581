import React, { useState, useEffect, componentWillMount } from 'react';
import Popup from 'reactjs-popup';
import axios from 'axios';
//

export default function ArtThumb(props) {

  const idNum = props.idNum;
  const [artwork, setArtwork] = useState([]);
  const [clickedImageIndex, setClickedImageIndex] = useState(null); // New state for clicked image index
  const [isLargeImagePopupOpen, setIsLargeImagePopupOpen] = useState(false); // Track larger image popup


  const openLargeImagePopup = (index) => {
    setClickedImageIndex(index);
    setIsLargeImagePopupOpen(true);
  };
  
  const closeLargeImagePopup = () => {
    setClickedImageIndex(null);
    setIsLargeImagePopupOpen(false);
  };
  
//   componentWillMount() {
//       axios.get('http://localhost:1337/api/artworks/'+idNum+'?populate=*')
//       .then(res => setArtwork(res.data.data.attributes))
//       .catch(err => console.log(err));
// }
  useEffect(() => {
    axios.get("https://milasite-c778f411dc22.herokuapp.com/api/artworks/"+idNum+'?populate=*')
    .then(res => setArtwork(res.data.data.attributes))
    .catch(err => console.log(err));
    console.log(artwork.pics)
    }, []);
  
  // const ControlledPopup = () => {
  //   const [open, setOpen] = useState(false);
  //   const closeModal = () => setOpen(false);
  //   return (
  //     <div>
  //       <div className='genre-page'>
  //             <div className='artwork'>
  //               <img src={artwork.url} alt={artwork.title} />
  //               <h3>{artwork.title}</h3>
  //             </div>
  //         ))}
  //       </div>
  //       <Popup open={open} closeOnDocumentClick onClose={closeModal}>
  //         <div className="modal">
  //           <a className="close" onClick={closeModal}>
  //             &times;
  //           </a>
  //           Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae magni
  //           omnis delectus nemo, maxime molestiae dolorem numquam mollitia, voluptate
  //           ea, accusamus excepturi deleniti ratione sapiente! Laudantium, aperiam
  //           doloribus. Odit, aut.
  //         </div>
  //       </Popup>
  //     </div>
  //   );
  // };

  return (
    <div>
      <Popup 
      triggerOn = 'click'
      trigger={
        //stuff on default
        // <div className='art-card'>
        //   <img src={props.artwork.url} alt={props.artwork.title} />
        // </div>
        <div className='artwork'>
          {artwork.pics && <img src={ artwork.pics.data[0].attributes.formats && artwork.pics.data[0].attributes.formats.medium ? artwork.pics.data[0].attributes.formats.medium.url : artwork.pics.data[0].attributes.url} alt={artwork.title} />}
          {/* {console.log(artwork,"iojojojo") || <img src={"http://localhost:1337/"+artwork.pics.data[0].attributes.formats.medium.url} alt={artwork.title} />} */}
          <p>{artwork.title}</p>
        </div>
      } closeOnDocumentClick = {isLargeImagePopupOpen ? false : true} modal>
        {/* stuff on click */}
        <div className='artPopup'>
          <div className='artPopupImages'>
            {artwork.pics &&
              artwork.pics.data.map((pic,index) => (
                <img 
                key = {index}
                src={pic.attributes.formats && pic.attributes.formats.medium ? pic.attributes.formats.medium.url : pic.attributes.url }
                alt={artwork.title}
                onClick={() => openLargeImagePopup(index)}/>
              ))
            }
          </div>
          <h3>{artwork.title}</h3>
          {artwork.year && <h4>{artwork.year}</h4>}
          {artwork.medium && <h4>Medium: {artwork.medium}</h4>}
          {artwork.dimensions && <h4>Dimensions: {artwork.dimensions}</h4>}
          <p>{artwork.description}</p>
        </div>

      </Popup>
      {isLargeImagePopupOpen && (
        <div className="imagePopup" onClick={closeLargeImagePopup}>
          <img
            src={artwork.pics.data[clickedImageIndex].attributes.url}
            alt={artwork.title}
          />
        </div>
      )}
    </div>
  )
}
