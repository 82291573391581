import { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './about.css';

export default function About() {
  const [profile, setprofile] = useState({});
  useEffect(() => {
    axios.get('https://milasite-c778f411dc22.herokuapp.com/api/profiles/1?populate=image')
      .then(res => setprofile(res.data.data.attributes))
      .catch(err => console.log(err));
  }, []);

  return (
    <div class="about-container">
      {profile.image && <img src={profile.image.data.attributes.formats.medium.url} alt="profile" class="profile-image" />}
      <div class="name">Mila Fejzo</div>
      <div class="social-media-icons">
        <a href="https://www.instagram.com/milafejzoart/">
          <FontAwesomeIcon icon={faInstagram} size='lg' className='icon' />
        </a>
        <a href="mailto:milafejzoart@gmail.com">
          <FontAwesomeIcon icon={faEnvelope} size='lg' className='icon' />
        </a>
        <a href="https://www.linkedin.com/in/mila-fejzo/">
          <FontAwesomeIcon icon={faLinkedin} size='lg' className='icon' />
        </a>
        {/* <a href="#"><i class="fa fa-twitter"></i></a> */}
        {/* <!-- Add more social media icons as needed --> */}
      </div>
      <h1>
        Bio:
      </h1>
      <p class="description">
        {profile.bio}
      </p>
      <h1>
        Statment:
      </h1>
      <p class="description">
        {profile.statement}
      </p>
      <h1>
        Resume:
      </h1>
      <iframe className='emb' src="https://docs.google.com/document/d/e/2PACX-1vQ0yPfdh3x-Avkz_CLAZhf_nMZVD_8YHdf4jXD4Ftw285gSikv878GtsF-sihd25mERooYGIlhRwtmY/pub?embedded=true"></iframe>
      <h1>
        CV:
      </h1>
      <iframe className="emb" src="https://docs.google.com/document/d/e/2PACX-1vQH9hwZ6Kxtw0HtW6HYve_D2c0z1gATe-glCziSnSDZjARo9Uv1d8akHaImh0cilh8i2xBOC-h9o0eZ/pub?embedded=true"></iframe>
    </div>
  );
}