import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function Navbar({isVisible}) {
  const [showNav, setShowNav] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      // Check the scroll position compared to the last position
      if (window.scrollY > lastScrollY) { // if scroll down hide the navbar
        setShowNav(false);
      } else { // if scroll up show the navbar
        setShowNav(true);
      }
      // Remember the last scroll position
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Add the scroll event listener when the component is mounted
      window.addEventListener('scroll', controlNavbar);

      // Remove the event listener when the component is unmounted
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  // Apply dynamic styles based on state
  const navStyle = {
    transform: showNav ? 'translateY(0)' : 'translateY(-100%)',
    transition: 'transform 0.3s ease-in-out',
  };
  return(
    <>
      <header className={`App-header ${isVisible ? '' : 'hidden'}`}>          
        <Link to= "/">
          <h1 style={{ color: 'black'}}>
            Mila Fejzo
          </h1>
        </Link>
        <div className="App-navbar">
          <ul>
              <li className='genre-thumb-button'>
                <Link to="/">Art</Link>
              </li>
              <li className='genre-thumb-button'>
                <Link to="/about">About</Link>
              </li>
          </ul>
          </div>
          <div class="social-media-icons">
            <a href="https://www.instagram.com/milafejzoart/">
              <FontAwesomeIcon icon={faInstagram} size='lg' className='icon' />
            </a>
            <a href="mailto:milafejzoart@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size='lg' className='icon' />
            </a>
            <a href="https://www.linkedin.com/in/mila-fejzo/">
              <FontAwesomeIcon icon={faLinkedin} size='lg' className='icon' />
            </a>
          {/* <a href="#"><i class="fa fa-twitter"></i></a> */}
          {/* <!-- Add more social media icons as needed --> */}
        </div>
      </header>
    </>
  )
}