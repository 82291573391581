
import axios from 'axios';
import './App.css';
import ArtThumb from './components/ArtThumb';
import { useState, useEffect} from 'react';
import { Link, Outlet } from "react-router-dom";
import GenrePage from './components/GenrePage';
import GenreThumb from './components/Genre';

export default function MainPage() {
    const [genres, setGenres] = useState([]);

    useEffect(() => {
        axios.get('https://milasite-c778f411dc22.herokuapp.com/api/genres?populate=*').then(res => {
            const sortedGenres = res.data.data.sort((a, b) => a.attributes.order - b.attributes.order);
            setGenres(sortedGenres);
        }).catch(err => console.log(err));
    }, []);

    return(
        <div className="Genre-gallery">
            {genres.map(genre => (
                <GenreThumb key={genre.id} title={genre.attributes.Title} artIDs = {genre.attributes.members.data.map(artwork => artwork.id)} />
            ))}
        </div>
    )
}
