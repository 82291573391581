import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import About from "./routes/about";
import MainPage from './mainpage';
import Navbar from './components/Navbar';
import GenrePage from './components/GenrePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
const App = () => {
  return (
    <BrowserRouter>
      <div className={`navbar`}>
        <Navbar />
      </div>
      <div className="content">
        <Routes>
          <Route exact path="/" element={<MainPage />} />
          <Route path="/about" element={<About/>} />
          <Route path="/art/:artids" element={<GenrePage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
