
import { useState, useEffect} from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ArtThumb from './ArtThumb';
import Macy from 'macy';

export default function GenrePage(){
  let {artids} = useParams();
  artids = artids.split(',');

  const [artworks, setArtworks] = useState([]);

  useEffect(() => {
    // Fetch all artworks and set them in the state
    Promise.all(artids.map(id => axios.get('https://milasite-c778f411dc22.herokuapp.com/' + `api/artworks/${id}?populate=*`)))
      .then(responses => {
        const fetchedArtworks = responses.map(res => res.data.data.attributes);
        setArtworks(fetchedArtworks);
      })
      .catch(err => console.log(err));
  }, [artids]);

useEffect(() => {
      const macyInstance = Macy({
          container: '.genre-page',
          trueOrder: false,
          waitForImages: true,
          margin: 32,
          columns: 2,
          breakAt: {
              1200: 1,
              940: 2,
              520: 1,
              400: 1
          }
      });
      document.querySelectorAll('.genre-page').forEach(img => {
        img.style.visibility = 'visible';
    });
}, [artworks]);
  
  return (
    <div className='genre-page'>
      {artids.map(id => (
          <ArtThumb key = {id} idNum = {id}/>
      ))}
    </div>
  )
}