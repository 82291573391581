import { useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/custom-animations/open-animation.css';
import 'react-awesome-slider/dist/styles.css';


// Use this for info on slider: https://www.npmjs.com/package/react-simple-image-slider
export default function GenreThumb(props) {
    const {title, artIDs} = props;
    const [index, setIndex] = useState(0);
    const [artTitles, setArtTitles] = useState([]); // [title1, title2, ...
    
    const AutoplaySlider = withAutoplay(AwesomeSlider);

    console.log(artIDs)
    const [images, setimages] = useState([])
    useEffect(() => {
      const getImages = async () => {
        let images = []
        let titles = []
        for (let i = 0; i < artIDs.length; i++) {
            let image = await axios.get("https://milasite-c778f411dc22.herokuapp.com" + "/api/artworks/" +artIDs[i]+"?populate=*");
            images.push({url : image.data.data.attributes.pics.data[0].attributes.formats.small.url});
            titles.push(image.data.data.attributes.title);
        }
        setimages(images);
        setArtTitles(titles);
      }
      getImages();
    }, [])
  
  return (
    <div className='genre-thumb hoverPulse'>
        <div className="card-content">
            <div className="card-top-section">
                <AwesomeSlider
                    animation="openAnimation"
                    className='card-image'
                    bullets= {false}
                    organicArrows= {false}
                    mobileTouch= {true}
                    // fillParent= {true}
                    onTransitionStart={({nextIndex}) => {
                        setIndex(nextIndex)
                    }}
                >
                    {images.map(image => (
                        <div className = "slider-thumb" data-src={image.url}/>
                    ))}
                </AwesomeSlider>
                <div className="card-text">
                    <h3>{title}</h3>
                    <p key={index} className="animate__animated animate__fadeIn">{artTitles[index]}</p>
                </div>
            </div>
            <Link to={"/art/"+artIDs} className='genre-thumb-button'>
              <h3>View Collection</h3>
            </Link>
        </div>
    </div>
  );
}